.affilates {
  position: relative;
  margin: 52px 0 0;

  @media (max-width: 991px) {
    margin: 30px 0 0;
  }

  &__bg {
    position: absolute;
    z-index: -1;
    top: 240px;
    left: 0;
    right: 0;
    pointer-events: none;

    @media (max-width: 767px) {
      top: auto;
      bottom: -194px;
    }

    img {
      width: 100%;
      gap: 100%;
      object-fit: contain;
    }
  }

  .con {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
  }
  &Body {
    display: flex;
    flex-wrap: wrap;
    margin: 40px 0 0;
    gap: 32px;

    @media (max-width: 991px) {
      margin: 20px 0 0;
      gap: 20px;
    }

    &__item {
      flex: 0 0 calc(50% - 16px);

      @media (max-width: 991px) {
        flex: 0 0 100%;
      }
    }
  }

  &__btn {
    margin: 50px auto 0;

    @media (max-width: 991px) {
      margin: 30px auto 0;
    }
  }
}
