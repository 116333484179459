.card {
  padding: 30px 26px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: radial-gradient(
      204.52% 102.86% at 102.94% -23.5%,
      rgba(128, 0, 255, 0.1) 0%,
      rgba(128, 0, 255, 0) 100%
    ),
    radial-gradient(
      237.57% 116.05% at -12.59% 121%,
      rgba(14, 96, 255, 0.1) 0%,
      rgba(14, 96, 255, 0) 100%
    ),
    rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(7.5px);

  @media (max-width: 991px) {
    padding: 18px 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
  }
  &__title {
    margin: 14px 0 0;
    color: #c6a47e;
    font-size: 28px;

    @media (max-width: 991px) {
      margin: 12px 0 0;
      font-size: 16px;
    }
  }
  &__text {
    margin: 14px 0 0;
    color: #fff;
    font-size: 16px;

    @media (max-width: 991px) {
      margin: 12px 0 0;
      font-size: 14px;
      text-align: center;
    }
  }
}
