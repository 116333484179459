.about {
  margin: -40px 0 0;

  @media (max-width: 991px) {
    margin: 0px 0 0;
  }

  &Body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before {
      content: '';
      display: block;
      width: 969px;
      height: 969px;
      border-radius: 50%;
      background: rgba(32, 39, 100, 0.3);
      filter: blur(250px);
      position: absolute;
      top: -200px;
      right: -600px;
      z-index: -1;
      pointer-events: none;

      @media (max-width: 767px) {
        top: -90px;
        right: -176px;
        width: 372px;
        height: 372px;
      }
    }

    &__title {
      display: flex;
      text-align: center;
    }

    &__text {
      margin: 40px auto 0;
      max-width: 1120px;

      @media (max-width: 991px) {
        margin: 30px auto 0;
      }

      .more {
        margin: 20px 0 0;
        height: 0;
        opacity: 0;
        pointer-events: none;
        transition: height ease 0.4s, opacity 0.6s ease;
        overflow: hidden;
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);

        &.open {
          height: auto;
          opacity: 1;
          pointer-events: unset;
        }
      }

      p {
        color: #fff;
        text-align: center;
        font-size: 20px;

        &:not(:first-child) {
          margin: 20px 0 0;
        }

        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
    }

    &__btn {
      margin: 40px auto 0;

      @media (max-width: 991px) {
        margin: 30px auto 0;
      }
    }
  }
}
