.main {
  position: relative;
  &__bg {
    position: absolute;
    z-index: -2;
    left: 0;
    right: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__inner {
    overflow: hidden;
  }

  &Body {
    position: relative;
    padding: 232px 0 260px;

    @media (max-width: 991px) {
      padding: 94px 0 50px;

      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      text-align: left;
      @media (max-width: 991px) {
        text-align: center;
        font-size: 22px;
      }
    }

    &__subtitle {
      margin: 75px 0 0;
      color: #fff;
      font-family: Poppins;
      font-size: 30px;
      line-height: 1.6;
      max-width: 320px;

      @media (max-width: 991px) {
        margin: 24px auto 0;
        text-align: center;
        font-size: 16px;
      }
    }

    &__btn {
      margin: 62px 0 0;

      @media (max-width: 991px) {
        margin: 270px auto 0;
      }
    }

    &__img {
      position: absolute;
      z-index: -1;
      bottom: 0;
      right: -340px;

      @media (max-width: 1100px) {
        width: 1000px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      @media (max-width: 991px) {
        bottom: 75px;
        width: 390px;
        right: calc(50% - 50px);
        transform: translate(50%, 0);
      }
    }
  }
}
