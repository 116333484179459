.accordion__content {
  transition: height ease 0.4s;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
.accordion__content.open {
  height: auto;
}

.accordion {
  padding: 18px 24px 22px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(7.5px);

  &:not(:first-child) {
    margin: 16px 0 0;

    @media (max-width: 767px) {
      margin: 20px 0 0;
    }
  }

  &.active {
    .accordion__arrow {
      img {
        transform: scale(1, -1);
      }
    }
    .accordion__content {
    }
  }

  &__btn {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    background-color: transparent;

    color: #c6a47e;
    font-size: 28px;

    @media (max-width: 767px) {
      align-items: flex-start;
      font-size: 16px;
    }
  }
  &__arrow {
    display: flex;
    flex: 0 0 auto;
    margin: 0 0 0 auto;
    padding: 0 0 0 14px;

    img {
      transition: transform 0.3s ease;
    }

    @media (max-width: 767px) {
      width: 30px;
      padding: 7px 0 0 14px;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
  &__content {
  }
  &__answer {
    margin: 24px 0 0;
    color: #fff;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: 767px) {
      margin: 12px 0 0;
      font-size: 12px;
      line-height: 18px;
    }
  }
}
