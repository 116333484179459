@font-face {
  font-family: Poppins;
  font-display: swap;
  src: url('assets/fonts/Poppins-Bold.woff2') format('woff2'),
    url('assets/fonts/Poppins-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Poppins;
  font-display: swap;
  src: url('assets/fonts/Poppins-Light.woff2') format('woff2'),
    url('assets/fonts/Poppins-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Poppins;
  font-display: swap;
  src: url('assets/fonts/Poppins-Regular.woff2') format('woff2'),
    url('assets/fonts/Poppins-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
