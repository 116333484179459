.footer {
  position: relative;
  margin: 50px 0 0;
  padding: 0 0 80px;

  @media (max-width: 991px) {
    margin: 40px 0 0;
    padding: 0 0 30px;
  }

  &::before {
    content: '';
    display: block;
    width: 969px;
    height: 969px;
    border-radius: 50%;
    background: rgba(32, 39, 100, 0.3);
    filter: blur(250px);
    position: absolute;
    bottom: 180px;
    z-index: -1;
    pointer-events: none;

    @media (max-width: 767px) {
      left: 50%;
      transform: translate(-50%, 0);
      width: 621px;
      height: 621px;
      bottom: 66px;
    }
  }

  &__bg {
    position: absolute;
    z-index: -2;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &Body {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 42px 0 47px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 991px) {
      padding: 20px 0;
      flex-direction: column;
      align-items: center;
    }

    &__logo {
      width: 120px;

      @media (max-width: 767px) {
        width: 90px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__title {
      color: #fff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 30px;

      @media (max-width: 991px) {
        font-size: 20px;
        margin: 12px 0 0;
      }
    }
    &__address {
      display: flex;
      align-items: center;

      @media (max-width: 991px) {
        margin: 20px 0 0;
      }

      span {
        display: block;
        color: #fff;
        font-size: 16px;
        margin: 0 0 0 14px;
      }
    }
    &__btn {
      @media (max-width: 991px) {
        margin: 20px 0 0;
      }
    }
  }

  &Links {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 36px 0 0;
    display: flex;
    justify-content: center;

    @media (max-width: 767px) {
      padding: 0 0 20px;
      flex-direction: column;
      align-items: center;
      border-top: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    &__item {
      color: #fff;
      font-size: 16px;
      line-height: 110%;
      transition: all 0.3s ease;

      &:hover {
        color: #c6a47e;
      }

      &:not(:first-child) {
        margin: 0 0 0 32px;

        @media (max-width: 767px) {
          margin: 20px 0 0;
        }
      }
    }
  }

  &__cr {
    margin: 36px auto 0;
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 300;
    text-align: center;

    @media (max-width: 767px) {
      margin: 28px auto 0;
    }
  }
}
