.title {
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Poppins;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;

  @media (max-width: 991px) {
    font-size: 30px;
  }

  i {
    font-style: normal;
    color: #c6a47e;
    font-weight: 700;
  }
}
