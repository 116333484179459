.adverisers {
  margin: 52px 0 0;

  @media (max-width: 991px) {
    margin: 30px 0 0;
  }

  .con {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
  }
  &Body {
    display: flex;
    flex-wrap: wrap;
    margin: 40px 0 0;
    gap: 32px;

    @media (max-width: 991px) {
      margin: 20px 0 0;
      gap: 20px;
    }

    &__item {
      flex: 0 0 calc(50% - 16px);

      @media (max-width: 991px) {
        flex: 0 0 100%;
      }
    }
  }

  &__btn {
    margin: 50px auto 0;

    @media (max-width: 991px) {
      margin: 30px auto 0;
    }
    @media (max-width: 340px) {
      padding: 8.5px 12px;
      width: 100%;
    }
  }
}
